export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(app)/admin": [6,[2,3]],
		"/(app)/admin/assignments": [7,[2,3]],
		"/(app)/admin/classes": [8,[2,3]],
		"/(app)/admin/classes/create": [9,[2,3]],
		"/(app)/admin/classes/edit": [10,[2,3]],
		"/(app)/admin/documents": [11,[2,3]],
		"/(app)/admin/evaluations": [12,[2,3]],
		"/(app)/admin/evaluations/create": [13,[2,3]],
		"/(app)/admin/evaluations/edit": [14,[2,3]],
		"/(app)/admin/models": [15,[2,3]],
		"/(app)/admin/models/create": [16,[2,3]],
		"/(app)/admin/models/edit": [17,[2,3]],
		"/(app)/admin/playground": [18,[2,3]],
		"/(app)/admin/prompts": [19,[2,3]],
		"/(app)/admin/prompts/create": [20,[2,3]],
		"/(app)/admin/prompts/edit": [21,[2,3]],
		"/(app)/admin/users": [22,[2,3]],
		"/auth": [25],
		"/auth/forgot": [26],
		"/classes": [27,[4]],
		"/classes/[id]": [28,[4]],
		"/(app)/c": [23,[2]],
		"/(app)/c/[id]": [24,[2]],
		"/error": [29],
		"/s/[id]": [30]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';